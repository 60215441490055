const footerMenuList = [
  {
    id: 1,
    title: "Documentation",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "https://docs.gexc.finance/information/gexc-finance/tokenomics",
        text: "Tokenomics",
      },
      {
        id: 2,
        href: "https://docs.gexc.finance/information/gexc-finance/roadmap",
        text: "Roadmap",
      },
      {
        id: 3,
        href: "https://docs.gexc.finance/information/gexc-finance/core-features",
        text: "Core Features",
      },
      {
        id: 4,
        href: "https://docs.gexc.finance/information/gexc-finance/core-features/security-system",
        text: "Security System",
      },
      {
        id: 5,
        href: "https://docs.gexc.finance/utility/gexc-crypto-exchange-app",
        text: "GEXC App",
      },

    ],
  },
  {
    id: 2,
    title: "Community",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "https://t.me/",
        text: "Telegram",
      },
      {
        id: 2,
        href: "https://twitter.com/",
        text: "Twitter",
      },
      {
        id: 3,
        href: "https://www.youtube.com/",
        text: "Youtube",
      },
      {
        id: 4,
        href: "https://medium.com/",
        text: "Medium",
      },
      {
        id: 5,
        href: "https://github.com/",
        text: "Github",
      },
    ],
  },
  {
    id: 3,
    title: "My Account",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "https://accounts.gexc.finance/register",
        text: "Register",
      },
      {
        id: 2,
        href: "https://accounts.gexc.finance/login",
        text: "Login",
      },
      {
        id: 3,
        href: "https://accounts.gexc.finance/password/forgot",
        text: "Forget Password",
      },
      {
        id: 4,
        href: "https://accounts.gexc.finance/contact",
        text: "Contact",
      },
    ],
  },
];

const socialIcons = [



];

export { footerMenuList, socialIcons };
